import React, { useState } from "react";
import CategorySkeletonLoader from "./CategorySkeletonLoader";
import CategoryCard from "./CategoryCard";
import { motion } from "framer-motion";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { listCategories } from "../../api/categories";
import { listEventsByMonth } from "../../api/events";
import EventCard from "./EventCard";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const getCurrentMonth = () => {
	const date = new Date();
	const currentMonthIndex = date.getMonth();
	return months[currentMonthIndex];
};

const variants = {
	visible: {
		opacity: 1,
		scale: 1,
		transition: { delay: 0.2, type: "spring", duration: 1 },
	},
	hidden: { opacity: 0, scale: 0 },
};

const titleVariants = {
	hidden: {
		scale: 0.8,
		opacity: 0,
	},
	visible: {
		scale: 1,
		opacity: 1,
		transition: {
			delay: 0.2,
		},
	},
};

const EventsList = () => {
	const queryClient = useQueryClient();

	const [selectedMonth, setSelectMonth] = useState(getCurrentMonth());

	const settingData = queryClient.getQueryData(["setting"]);

	const EventTitle = settingData?.find((item) => item.TextId == "EventsTitle")?.TextData;
	const EventDescription = settingData?.find((item) => item.TextId == "EventsDescription")?.TextData;

	const { data, isLoading, isError, error } = useQuery({
		queryKey: ["events", selectedMonth],
		queryFn: () => listEventsByMonth(selectedMonth),
	});

	return (
		<div className="courses-area pb-100" style={{ marginTop: 60 }}>
			<div className="container">
				<motion.div className="section-title" initial="hidden" animate="visible" variants={titleVariants}>
					<span className="top-title">{EventTitle}</span>
					<h2>{EventDescription}</h2>
				</motion.div>
				<div className="search-result">
					<div className="row align-items-center">
						<div className="offset-3 col-6 offset-3">
							<select className="form-select form-control" name="short" value={selectedMonth} onChange={(e) => setSelectMonth(e.target.value)}>
								{months?.map((month) => (
									<option key={month} value={month}>
										{month}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
				<div className="row justify-content-center">
					{isLoading ? (
						<CategorySkeletonLoader />
					) : isError ? (
						<h3 className="text-center">{error}</h3>
					) : (
						<>
							{data.length > 0 ? (
								data.map((event) => <EventCard key={event.ID} event={event} />)
							) : (
								<h3 className="text-center">There are no events in this month!</h3>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default EventsList;
