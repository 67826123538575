import Footer from "./Footer";
import Navbar from "./Navbar";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { IoIosRefreshCircle } from "react-icons/io"; //import desired icon
import { useQuery } from "@tanstack/react-query";
import { listSettings } from "../../api/settings";

const Layout = () => {
	const { isLoading, isError, error } = useQuery({
		queryKey: ["setting"],
		queryFn: () => listSettings(),
	});

	return isLoading ? (
		<div className="overlay">
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					position: "fixed",
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					zIndex: 9999,
					background: "#322e78",
				}}
			>
				<IoIosRefreshCircle color="white" size="5em" style={{ animation: "spin 1s linear infinite" }} />
			</div>
		</div>
	) : isError ? (
		<h3 className="text-center">{error}</h3>
	) : (
		<>
			<ScrollRestoration />
			<Navbar />
			<Outlet />
			<Footer />
		</>
	);
};

export default Layout;
