import axios from "axios";
import { publicUrl, generalHeaders } from "./config";

export const submitInquirySubmission = async (data) => {
	try {
		const config = {
			...generalHeaders,
			url: `${publicUrl}/inquiry-submission`,
			data: JSON.stringify({
				apiKey: "5AquNnADKpp3CipYg36b2sDoPIAJvFI",
				data: data,
			}),
		};
		const response = await axios(config);
		if (response.data.result == 1) {
			throw new Error(response.data.msg);
		} else {
			return response;
		}
	} catch (error) {
		throw new Error(error);
	}
};
