import Skeleton from "react-loading-skeleton";

const CategorySkeletonLoader = () => {
	return (
		<>
			<div className="col-lg-3 col-sm-6">
				<div className="single-courses-box">
					<div className="courses-image">
						<Skeleton height="200px" width="100%" />
					</div>
					<div className="courses-content">
						<Skeleton width="100%" />
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-sm-6">
				<div className="single-courses-box">
					<div className="courses-image">
						<Skeleton height="200px" width="100%" />
					</div>
					<div className="courses-content">
						<Skeleton width="100%" />
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-sm-6">
				<div className="single-courses-box">
					<div className="courses-image">
						<Skeleton height="200px" width="100%" />
					</div>
					<div className="courses-content">
						<Skeleton width="100%" />
					</div>
				</div>
			</div>
			<div className="col-lg-3 col-sm-6">
				<div className="single-courses-box">
					<div className="courses-image">
						<Skeleton height="200px" width="100%" />
					</div>
					<div className="courses-content">
						<Skeleton width="100%" />
					</div>
				</div>
			</div>
		</>
	);
};

export default CategorySkeletonLoader;
