import { useState } from "react";
import { motion } from "framer-motion";
import { FaCalendar, FaClock } from "react-icons/fa";
import formatDate from "../../utils/formatDate";
import formatTime from "../../utils/formatTime";
import EventModal from "../UI/EventModal";
const variants = {
	visible: {
		opacity: 1,
		scale: 1,
		transition: { delay: 0.2, type: "spring", duration: 1 },
	},
	hidden: { opacity: 0, scale: 0 },
};

const EventCard = ({ event }) => {
	const { ID, EventDate, EventName, ImgURL } = event;
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		document.body.style.overflow = "unset";
	};
	return (
		<>
			{show && <EventModal show={show} handleClose={handleClose} item={event} />}

			<motion.div initial="hidden" whileInView="visible" variants={variants} className="col-lg-3 col-md-4 col-sm-6">
				<div className="single-courses" style={{ height: "100%", cursor: "pointer" }} onClick={() => setShow(true)}>
					<div className="courses-main-img">
						<img src={ImgURL} alt={ID} />
					</div>
					<div className="courses-content">
						<h3>{EventName}</h3>
					</div>
					<div className="courses-hover-content py-5 d-flex flex-column align-items-center">
						<p style={{ fontSize: "20px", fontWeight: "bold" }}>More Details ...</p>
					</div>
				</div>
			</motion.div>
		</>
	);
};

export default EventCard;
