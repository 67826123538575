import React from "react";
import Banner from "../UI/Banner";
import CategoriesList from "../UI/CategoriesList";
import Slider from "../UI/Slider";
import Form from "../UI/Form";

const Home = () => {
	return (
		<>
			<Banner />
			<Slider />
			<CategoriesList />
			<Form />
		</>
	);
};

export default Home;
