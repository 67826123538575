import { motion } from "framer-motion";
import Modal from "../UI/Modal";
import { useState } from "react";

const variants = {
	visible: {
		opacity: 1,
		scale: 1,
		transition: { delay: 0.2, type: "spring", duration: 1 },
	},
	hidden: { opacity: 0, scale: 0 },
};

const ItemCard = ({ item }) => {
	const { ID, ItemName, ImgURL } = item;

	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		document.body.style.overflow = "unset";
	};

	return (
		<>
			{show && <Modal show={show} handleClose={handleClose} item={item} />}
			<motion.div initial="hidden" whileInView="visible" variants={variants} className="col-lg-3 col-md-4 col-sm-6">
				<div className="single-courses">
					<div className="courses-main-img ">
						<img src={ImgURL} alt={ID} />
					</div>
					<div className="courses-content">
						<h3>{ItemName}</h3>
					</div>
					<div className="courses-hover-content" style={{ height: "100%", cursor: "pointer" }} onClick={() => setShow(true)}>
						<div className="sk">
							<div>
								<h3>
									<div className="d-flex align-items-center" style={{ height: "100%", color: "#fff" }}>
										{ItemName}
									</div>
								</h3>
							</div>
						</div>
					</div>
				</div>
			</motion.div>
		</>
	);
};

export default ItemCard;
