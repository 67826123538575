import PageBanner from "../UI/PageBanner";
import EventsList from "../UI/EventsList";

const Events = () => {
	return (
		<>
			<PageBanner pageTitle="Events" homePageUrl="/" homePageText="Home" activePageText="Events" />
			<EventsList />
		</>
	);
};

export default Events;
