import PageBanner from "../UI/PageBanner";
import CategoriesList from "../UI/CategoriesList";

const Categories = () => {
	return (
		<>
			<PageBanner pageTitle="Categories" homePageUrl="/" homePageText="Home" activePageText="Categories" />
			<CategoriesList />
		</>
	);
};

export default Categories;
