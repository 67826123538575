import axios from "axios";
import { publicUrl, generalHeaders } from "./config";

export const listCategories = async (page = 1, perPage = 1000, orderBy = "ID", orderDirection = "asc") => {
	try {
		const config = {
			...generalHeaders,
			url: `${publicUrl}/categories/list`,
			data: JSON.stringify({
				apiKey: "5AquNnADKpp3CipYg36b2sDoPIAJvFI",
				data: {
					Page: page,
					perPage: perPage,
					orderBy: orderBy,
					orderDirection: orderDirection,
				},
			}),
		};
		const response = await axios(config);
		const data = response.data.response?.data;
		return data;
	} catch (error) {
		throw new Error(error);
	}
};
