import { useParams } from "react-router-dom";
import PageBanner from "../UI/PageBanner";
import ItemsList from "../UI/ItemsList";

const CategoryPage = () => {
	const { id, categ_name } = useParams();
	return (
		<>
			<PageBanner pageTitle="Items" homePageUrl="/" homePageText="Home" activePageText={categ_name} />
			<ItemsList id={id} />
		</>
	);
};

export default CategoryPage;
