import { motion } from "framer-motion";

const Backdrop = ({ children, onClick }) => {
	return (
		<>
			{children}
			<motion.div
				onClick={onClick}
				className="modal-backdrop"
				style={{
					background: "rgba(0, 0, 0, 0.2) !important",
					// backdropFilter: "blur(20px)",
				}}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			></motion.div>
		</>
	);
};

export default Backdrop;
