import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { FaHome, FaList, FaCamera, faC } from "react-icons/fa";

const Navbar = () => {
	const [menu, setMenu] = useState(true);

	const toggleNavbar = () => {
		setMenu(!menu);
	};

	useEffect(() => {
		let elementId = document.getElementById("navbar");
		document.addEventListener("scroll", () => {
			if (window.scrollY > 170) {
				elementId.classList.add("is-sticky");
			} else {
				elementId.classList.remove("is-sticky");
			}
		});
	});

	const classOne = menu ? "collapse navbar-collapse" : "collapse navbar-collapse show";
	const classTwo = menu ? "navbar-toggler navbar-toggler-right collapsed" : "navbar-toggler navbar-toggler-right";

	return (
		<>
			<div id="navbar" className="navbar-area">
				<div className="desktop-nav">
					<div className="container-fluid">
						<div className="navbar navbar-expand-lg navbar-light">
							<NavLink className="navbar-brand" to="/" onClick={toggleNavbar}>
								<img src="/images/Marketplace_Horizointal.png" alt="logo" style={{ width: "150px" }} />
							</NavLink>
							<button onClick={toggleNavbar} className={classTwo} type="button">
								<span className="icon-bar top-bar"></span>
								<span className="icon-bar middle-bar"></span>
								<span className="icon-bar bottom-bar"></span>
							</button>

							<div className={classOne} id="navbarSupportedContent">
								<ul className="navbar-nav ms-auto">
									<motion.li
										className="nav-item"
										whileHover={{
											scale: 1.1,
											transition: { duration: 0.5 },
										}}
										whileTap={{ scale: 0.9 }}
									>
										<NavLink to="" onClick={toggleNavbar} className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
											<FaHome size="1em" style={{ margin: "0 10 0 0" }} /> Home
										</NavLink>
									</motion.li>
									<motion.li
										className="nav-item"
										whileHover={{
											scale: 1.1,
											transition: { duration: 0.5 },
										}}
										whileTap={{ scale: 0.9 }}
									>
										<NavLink to="categories" onClick={toggleNavbar} className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
											<FaList size="1em" style={{ margin: "0 10 0 0" }} /> Categories
										</NavLink>
									</motion.li>
									<motion.li
										className="nav-item"
										whileHover={{
											scale: 1.1,
											transition: { duration: 0.5 },
										}}
										whileTap={{ scale: 0.9 }}
									>
										<NavLink to="events" onClick={toggleNavbar} className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>
											<FaCamera size="1em" style={{ margin: "0 10 0 0" }} /> Events
										</NavLink>
									</motion.li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Navbar;
