import React from "react";
import CategorySkeletonLoader from "./CategorySkeletonLoader";
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { listItemsByCategId } from "../../api/items";
import ItemCard from "./ItemCard";
import FilterDropdown from "./FilterDropdown";
import { listCategories } from "../../api/categories";

const variants = {
	visible: {
		opacity: 1,
		scale: 1,
		transition: { delay: 0.2, type: "spring", duration: 1 },
	},
	hidden: { opacity: 0, scale: 0 },
};

const titleVariants = {
	hidden: {
		scale: 0.8,
		opacity: 0,
	},
	visible: {
		scale: 1,
		opacity: 1,
		transition: {
			delay: 0.2,
		},
	},
};

const ItemsList = ({ id }) => {
	const { data: categories } = useQuery({
		queryKey: ["categories"],
		queryFn: () => listCategories(),
	});

	const category = categories?.find((category) => category.ID == id);

	const { data, isLoading, isError, error } = useQuery({
		queryKey: ["items", id],
		queryFn: () => listItemsByCategId(id),
	});

	return (
		<div className="courses-area pb-100" style={{ marginTop: 60 }}>
			<div className="container">
				<motion.div className="section-title" initial="hidden" animate="visible" variants={titleVariants}>
					<h2 className="top-title">{category?.CategDescription}</h2>
				</motion.div>
				<div className="search-result">
					<div className="row align-items-center">
						<div className="offset-3 col-6 offset-3">
							<FilterDropdown id={id} categories={categories} />
						</div>
					</div>
				</div>
				<div className="row justify-content-center">
					{isLoading ? (
						<CategorySkeletonLoader />
					) : isError ? (
						<h3 className="text-center">{error}</h3>
					) : (
						<>
							{data.length > 0 ? (
								data.map((item) => <ItemCard key={item.ID} item={item} />)
							) : (
								<h3 className="text-center mt-4">There are not items in this category!</h3>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ItemsList;
