import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { useQuery } from "@tanstack/react-query";
import { listGallery } from "../../api/gallery";

const useScreenSize = () => {
	const [screenSize, setScreenSize] = useState("big");
	useEffect(() => {
		function handleResize() {
			if (window.innerWidth < 768) {
				setScreenSize("small");
			} else if (window.innerWidth >= 768 && window.innerWidth < 992) {
				setScreenSize("mid");
			} else {
				setScreenSize("big");
			}
		}
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return screenSize;
};

const Slider = () => {
	const screenSize = useScreenSize();

	const { data, isLoading, isError, error } = useQuery({
		queryKey: ["gallery"],
		queryFn: () => listGallery(),
	});

	return isLoading ? (
		<></>
	) : isError ? (
		<h3 className="text-center">{error}</h3>
	) : (
		<Swiper
			style={{ padding: "0px 0px 50px 0px", borderBottom: "#dde1e1 solid 1px", background: "#f6fafb" }}
			modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false,
			}}
			slidesPerView={screenSize === "big" ? 3 : screenSize === "mid" ? 2 : 1}
			spaceBetween={10}
			// navigation
			loop={true}
			grabCursor={true}
			centeredSlides={true}
			pagination={{ clickable: true }}
			// onSwiper={(swiper) => console.log(swiper)}
			// onSlideChange={() => console.log("slide with no effect")}
		>
			{data?.map((item, index) => (
				<SwiperSlide key={index}>
					<img src={item.ImgURL} alt="1" />
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default Slider;
