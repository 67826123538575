import React from "react";
import CategorySkeletonLoader from "./CategorySkeletonLoader";
import CategoryCard from "./CategoryCard";
import { motion } from "framer-motion";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { listCategories } from "../../api/categories";

const variants = {
	visible: {
		opacity: 1,
		scale: 1,
		transition: { delay: 0.2, type: "spring", duration: 1 },
	},
	hidden: { opacity: 0, scale: 0 },
};

const titleVariants = {
	hidden: {
		scale: 0.8,
		opacity: 0,
	},
	visible: {
		scale: 1,
		opacity: 1,
		transition: {
			delay: 0.2,
		},
	},
};

const CategoriesList = () => {
	const queryClient = useQueryClient();

	const settingData = queryClient.getQueryData(["setting"]);

	const categoriesTitle = settingData?.find((item) => item.TextId == "CategoriesTitle")?.TextData;
	const categoriesDescription = settingData?.find((item) => item.TextId == "CategoriesDescription")?.TextData;

	const { data, isLoading, isError, error } = useQuery({
		queryKey: ["categories"],
		queryFn: () => listCategories(),
	});

	return (
		<div className="courses-area pb-100" style={{ marginTop: 60 }}>
			<div className="container">
				<motion.div className="section-title" initial="hidden" animate="visible" variants={titleVariants}>
					<span className="top-title">{categoriesTitle}</span>
					<h2>{categoriesDescription}</h2>
				</motion.div>
				<div className="row justify-content-center">
					{isLoading ? (
						<CategorySkeletonLoader />
					) : isError ? (
						<h3 className="text-center">{error}</h3>
					) : (
						<>
							{data.length > 0 ? (
								data.map((category) => (
									// <motion.div initial="hidden" whileInView="visible" variants={variants} className="col-lg-3">
									<CategoryCard key={category.ID} category={category} />
									// </motion.div>
								))
							) : (
								<h3 className="text-center">No Data</h3>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default CategoriesList;
