import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/UI/Layout";
import Home from "./components/Home/Home";
import Categories from "./components/Categories/Categories";
import CategoryPage from "./components/CategoryPage/CategoryPage";
import Events from "./components/Events/Events";

const router = createBrowserRouter([
	{
		id: "root",
		path: "/",
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: "categories",
				element: <Categories />,
			},
			{
				path: "categories/:id/:categ_name",
				element: <CategoryPage />,
			},
      {
				path: "events",
				element: <Events />,
			},
		],
	},
]);

const App = () => <RouterProvider router={router} />;

export default App;
