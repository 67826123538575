import AnimatedCharacters from "./BannerText";
import { motion } from "framer-motion";
import { useQueryClient } from "@tanstack/react-query";

const variants = {
	visible: {
		transition: {
			staggerChildren: 0.025,
		},
	},
};

const pVariants = {
	hidden: {
		scale: 0.8,
		opacity: 0,
	},
	visible: {
		scale: 1,
		opacity: 1,
		transition: {
			delay: 1.9,
		},
	},
};

const Banner = () => {
	const queryClient = useQueryClient();

	const data = queryClient.getQueryData(["setting"]);

	const headingText = [
		{
			type: "heading",
			text: data?.find((item) => item.TextId == "BannerTitle")?.TextData,
		},
	];

	const bannerDescription = data?.find((item) => item.TextId == "BannerDescription")?.TextData;

	return (
		<div className="banner-area bg-1 container-fluid">
			<div className="bg-overlay">
				<div className="container-fluid">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="banner-img">
								{/* <motion.img initial={{ scale: 2 }} animate={{ scale: 1 }} transition={{ duration: 1 }} src="/images/banner-img-1.png" alt="banner" /> */}
								<img src="/images/banner-img-1.png" alt="banner" />
							</div>
						</div>
						<div className="col-lg-6">
							<motion.div initial="hidden" animate="visible" variants={variants} className="banner-content">
								{headingText.map((item, index) => (
									<AnimatedCharacters {...item} key={index} />
								))}
								<motion.p
									initial={{ scale: 0 }}
									animate={{ scale: 1 }}
									variants={pVariants}
									dangerouslySetInnerHTML={{
										__html: bannerDescription,
									}}
								>
									{/* <motion.p initial="hidden" animate="visible" variants={pVariants}> */}
								</motion.p>
							</motion.div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Banner;
