import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const FilterDropdown = ({ id, categories }) => {
	const navigate = useNavigate();
	const [selectedCategory, setSelectedCategory] = useState("");

	const handleChange = (event) => {
		const selectedOption = event.target.options[event.target.selectedIndex];
		const categName = selectedOption.getAttribute("data-categname");
		setSelectedCategory(categName);
		console.log("Selected categname:", categName);
		navigate(`/categories/${event.target.value}/${categName}`, { preventScrollReset: true });
	};

	return (
		<select className="form-select form-control" name="short" value={id} onChange={handleChange}>
			{categories?.map((item) => (
				<option key={item.ID} value={item.ID} data-categname={item.CategName}>
					{item.CategName}
				</option>
			))}
		</select>
	);
};

export default FilterDropdown;
