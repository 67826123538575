import { motion } from "framer-motion";
import { useState, useRef } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { submitInquirySubmission } from "../../api/inquiry-submission";

const Form = () => {
	const form = useRef(null);

	const queryClient = useQueryClient();

	const settingData = queryClient.getQueryData(["setting"]);

	const formTitle = settingData?.find((item) => item.TextId == "FormTitle")?.TextData;
	const formDescription = settingData?.find((item) => item.TextId == "FormDescription")?.TextData;

	const [NameOfCompany, setNameOfCompany] = useState("");
	const [NameOfRepresentative, setNameOfRepresentative] = useState("");
	const [ContactNumber, setContactNumber] = useState("");
	const [Email, setEmail] = useState("");

	const [error, setError] = useState("");

	const { mutate } = useMutation({
		mutationFn: async () => {
			const data = {
				NameOfCompany,
				NameOfRepresentative,
				ContactNumber,
				Email,
			};
			await submitInquirySubmission(data);
		},
		onSuccess: () => {
			toast.success("Inquiry submitted successfully!", {
				style: {
					border: "1px solid #4BB543",
					padding: "16px",
					color: "#4BB543",
				},
				iconTheme: {
					primary: "#4BB543",
					secondary: "#FFFAEE",
				},
			});
			setNameOfCompany("");
			setNameOfRepresentative("");
			setContactNumber("");
			setEmail("");
			form.current?.reset();
		},
		onError: () => {
			toast.error("There was an error in submitting the inquiry, please try again", {
				style: {
					border: "1px solid #ff0033",
					padding: "16px",
					color: "#ff0033",
				},
				iconTheme: {
					primary: "#ff0033",
					secondary: "#FFFAEE",
				},
			});
		},
	});

	const handleSubmit = (e) => {
		e.preventDefault();
		if (NameOfCompany === "" || NameOfRepresentative === "" || ContactNumber === "" || Email === "") {
			setError("Please fill all the fields!");
		} else {
			mutate();
			setError("");
		}
	};

	return (
		<div className="register-area ptb-100">
			<div className="container">
				<div className="section-title">
					<span className="top-title">{formTitle}</span>
					<h2>{formDescription}</h2>
				</div>
				<div className="row align-items-center">
					<div className="col-lg-12 px-5 pb-5">
						<div className="teaching-img">
							<img src="/images/teaching-img-3.png" alt="teaching" />
						</div>
					</div>
					<div className="offset-md-2 col-md-8 col-xs-12 col-sm-12 offset-md-2">
						<div className="register-form">
							<motion.form ref={form} onSubmit={handleSubmit} initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }}>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										placeholder="Name of Company"
										name="NameOfCompany"
										NameOfCompany={NameOfCompany}
										onChange={(e) => setNameOfCompany(e.target.value)}
										required
									/>
								</div>
								<div className="form-group">
									<input
										type="text"
										className="form-control"
										placeholder="Name of Representative"
										name="NameOfRepresentative"
										NameOfCompany={NameOfRepresentative}
										onChange={(e) => setNameOfRepresentative(e.target.value)}
										required
									/>
								</div>
								<div className="form-group">
									<input
										type="number"
										className="form-control"
										placeholder="Contact Number"
										name="ContactNumber"
										NameOfCompany={ContactNumber}
										onChange={(e) => setContactNumber(e.target.value)}
										required
									/>
								</div>
								<div className="form-group">
									<input
										type="email"
										className="form-control"
										placeholder="Email"
										name="Email"
										NameOfCompany={Email}
										onChange={(e) => setEmail(e.target.value)}
										required
									/>
								</div>
								<p className="my-4" style={{ color: "red", fontWeight: "bold" }}>
									{error}
								</p>
								<motion.button type="submit" className="default-btn" whileTap={{ scale: 0.4 }}>
									Submit
								</motion.button>
							</motion.form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Form;
